<template>
  <footer class="main-footer" id="ft-b" data-aos="fade-up">
    <Logo />
    <div class="main-footer__content">
      <ul class="list-menu" v-if="language && Object.keys(language).length > 0">
        <li
          class="list-menu__item"
          v-for="(menu, index) in dataMenu"
          :key="index"
        >
          <router-link :to="menu.link">
            <p
                v-html="language[menu.title][locale]"
                @click="setMenuAvtive(index, -1)"
                :class="[
                  dataMenuActive.main == index ? 'activep' : 'activep'+ index ,,
                  'text-p',
                ]"
              ></p>
          </router-link>
          <ul class="sub-menu">
            <li
              class="sub-menu__item"
              v-for="(sub, index1) in menu.sub"
              :key="index1"
            >
              <router-link :to="sub.link">
                <p
                  v-html="language[sub.title][locale]"
                  @click="setMenuAvtive(index, index1)"
                  :class="[
                    (dataMenuActive.sub == index1 && menuActive == index) ? 'activec' : 'activec' + index,
                    'text-c',
                  ]"
                ></p>
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
      <div class="list-social">
        <div class="item">
          <div class="item__icon">
            <a :href="commons?.fanpage_facebook && commons?.fanpage_facebook !== 'null' ? commons?.fanpage_facebook : '#'" target="_blank">
              <img src="../assets/img/icons/fb.svg" alt="fb" class="fb" />
            </a>
          </div>
        </div>
        <div class="item">
          <div class="item__icon">
            <a :href="commons?.linkedin && commons?.linkedin !== 'null' ? commons?.linkedin :  '#'" target="_blank">
              <img src="../assets/img/icons/in.svg" alt="in" />
            </a>
          </div>
        </div>
        <div class="item">
          <div class="item__icon">
            <a :href="commons?.youtube && commons?.youtube !== 'null' ?  commons?.youtube : '#'" target="_blank">
              <img src="../assets/img/icons/youtube.svg" alt="youtube" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="copy-right">
      <div class="copy-right__content flex justify-between content-k">
        <p>© 2022 TAY BAC HO XA INDUSTRY. All Rights Reserved.</p>
        <p>
          <!-- {{ trans("frontend.designed by") }} -->
          Design By
          <a href="https://saijinagency.com"
            ><span class="saijin"> Saijin Agency</span>
          </a>
        </p>
      </div>
    </div>
    <div class="scroll-top">
      <img src="../assets/img/icons/arrow-right.svg" alt="scroll top"  @click="scrollToTop()"/>
    </div>
  </footer>
  <div id="fb-root" />
  <div id="fb-customer-chat" class="fb-customerchat" />
</template>
<script>
/* global $ */
import CommonSerive from "@/services/CommonSerive";
import Logo from "./logo/logo.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    Logo,
  },
  data() {
    return {
      dataMenu: [
        {
          title: "menu::investor",
          link: "/investor",
          sub: [
            {
              title: "menu::aboutholdings",
              link: "/investor",
            },
            {
              title: "menu::ourprojects",
              link: "/investor",
            },
          ],
        },
        {
          title: "menu::industrial",
          link: "/about",
          sub: [
            {
              title: "menu::overview",
              link: "/about",
            },
            {
              title: "menu::subdivisionfloorplan",
              link: "/about",
            },
            {
              title: "menu::product",
              link: "/about",
            },
            {
              title: "menu::infrastructure",
              link: "/about",
            },
            {
              title: "menu::attractiveindustry",
              link: "/about",
            },
          ],
        },
        {
          title: "menu::advantages",
          link: "/why-choose",
          sub: [
            {
              title: "menu::competitiveadvantage",
              link: "/why-choose",
            },
            {
              title: "menu::criticalposition",
              link: "/why-choose",
            },
            {
              title: "menu::investmentpolicy",
              link: "/why-choose",
            },
            // {
            //   title: "Tiềm năng phát triển <br> Tây Bắc Hồ Xá",
            //   link: "/why-choose",
            // },
          ],
        },
        {
          title: "menu::quangtri",
          link: "/info",
          sub: [
            {
              title: "home::connectability",
              link: "/info",
            },
            {
              title: "home::resourcesmaterials",
              link: "/info",
            },
            {
              title: "home::laborcosts",
              link: "/info",
            },
          ],
        },
        {
          title: "menu::news",
          link: "/news",
          sub: [
            {
              title: "menu::news",
              link: "/news",
            },
            {
              title: "home::recruitment",
              link: "/news",
            },
          ],
        },
        {
          title: "menu::contact",
          link: "/contact",
        },
      ],
      dataMenuActive: {
        main: -1,
        sub:  -1,
      },
      commons:{},
      language: [],
    };
  },
  computed: {
    ...mapGetters(["menuActive", "subActive", "dataMenu", "locale"]),
  },
   watch: {
    language: function () {
      this.$forceUpdate();
    },
  },
  created() {
    this.dataHome();
  },
  mounted() {
    if (
      localStorage.getItem("locale") == null ||
      localStorage.getItem("locale") == "null"
    ) {
      this.setLang("vi");
    } else {
      this.setLang(localStorage.getItem("locale"));
    }
    // $(document).ready(function () {
    //   $(".menu-toggle .item").click(function (event) {
    //     if (event.target.classList.value.includes("active")) {
    //       $(".menu-toggle .item").removeClass("active");
    //       return;
    //     }
    //     $(".menu-toggle .item").removeClass("active");
    //     event.target.classList.toggle("active");
    //   });
    //   $(".sub-menu li").click(function (event2) {
    //     $(".menu-b li a").removeClass("active");
    //     event2.target.classList.toggle("active");
    //   });
    // });
    // this.$nextTick(function () {
    // });
    this.getDataMenu();
    this.getInfo();
    this.dataHome();
    // this.setFb();
  },
  methods: {
    ...mapActions(["setLang", "setMenu"]),
    ...mapMutations(["setInfo", "setDataMenu"]),
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    activeMenuP(index, sub) {
      this.$store.commit("setMenuActive", index);
      if ((index === 0 || index === 4 || index === 5) && sub === true) {
        this.$store.commit("setSubActive", true);
      } else {
        this.$store.commit("setSubActive", sub);
      }
    },
    setMenuAvtive(main, sub) {
      this.dataMenuActive.main = main;
      this.dataMenuActive.sub = sub;
      this.setMenu(this.dataMenuActive);
      $('.text-p').removeClass("activep");
      $('.activep' + main).addClass("activep");
      $('.text-c').removeClass("activec");
      $(".activep" + main + " "  + '.activec' + sub).addClass("activec");
      this.$forceUpdate();
    },
    async getDataMenu() {
      await CommonSerive.getMenu()
        .then((resp) => {
          // this.dataMenu = resp.data.data;
          // this.dataMenu = this.dataMenu.filter((x, index) => {
          //   if (index != 5 && index != 6 && index != 7) {
          //     return x;
          //   }
          // });
          this.setDataMenu(resp.data.data);
        })
        .catch(() => {});
    },
    async getInfo() {
      await CommonSerive.getInformation()
        .then((resp) => {
          this.info = resp.data.data;
          // console.log('resp.data.data', )
          this.commons = resp.data.data
          this.setInfo(this.info);
        })
        .catch(() => {});
    },
    async dataHome() {
      await CommonSerive.getLang()
        .then((resp) => {
          this.language = resp.data.data
        })
        .catch(() => {});
    },
    setFb() {
      $(document).ready(function () {
        var chatbox = document.getElementById("fb-customer-chat");
        if (chatbox) {
          chatbox.setAttribute("page_id", "1788643954787205");
          chatbox.setAttribute("attribution", "biz_inbox");
        }
        window.fbAsyncInit = function () {
          // eslint-disable-next-line no-undef
          FB.init({
            xfbml: true,
            version: "v13.0",
          });
        };

        (function (d, s, id) {
          var js,
            fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s);
          js.id = id;
          js.src =
            "https://connect.facebook.net/vi_VN/sdk/xfbml.customerchat.js";
          fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");
      });
    },
  },
};
</script>
