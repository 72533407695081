<template>
  <div class="social-left1">
    <div class="list-social">
      <!-- <div class="social__phone item">
        <div
          class="quick-alo-phone quick-alo-green quick-alo-show"
          id="quick-alo-phoneIcon"
        >
          <a href="tel:0889886086" title="Liên hệ nhanh">
            <div class="quick-alo-ph-circle"></div>
            <div class="quick-alo-ph-circle-fill"></div>
            <div class="quick-alo-ph-img-circle"></div>
          </a>
        </div>
      </div> -->
      <div id="play-isc-video">
        <span class="caret">
          <a href="tel:0889886086" title="Liên hệ nhanh"></a>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["info"]),
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@-webkit-keyframes scaleIn {
  from {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 0.5;
  }
  to {
    -webkit-transform: scale(2.5, 2.5);
    transform: scale(2.5, 2.5);
    opacity: 0;
  }
}

@keyframes scaleIn {
  from {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 0.5;
  }
  to {
    -webkit-transform: scale(2.5, 2.5);
    transform: scale(2.5, 2.5);
    opacity: 0;
  }
}
#play-isc-video {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 75px;
  height: 75px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  width: 61px;
  height: 61px;
  background-color: #f37022;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  @media screen and (max-width: 765px) {
    width: 60px;
    height: 60px;
  }
}
// #play-isc-video:before,
// #play-isc-video:after {
//   content: "";
//   border-radius: 50%;
//   background-color: #f37022;
//   width: 75px;
//   height: 75px;
//   @media screen and (max-width: 765px) {
//     width: 60px;
//     height: 60px;
//   }
//   position: absolute;
//   top: 0;
//   left: 0;
//   z-index: -1;
//   opacity: 0;
//   -webkit-animation: scaleIn 2s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
//   animation: scaleIn 2s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
//   -webkit-box-sizing: content-box;
//   box-sizing: content-box;
// }
// #play-isc-video:after {
//   -webkit-animation-delay: 1s;
//   animation-delay: 1s;
// }
#play-isc-video .caret {
      box-shadow: rgb(0 0 0 / 15%) 0px 4px 12px 0px;
  content: "";
  display: inline-block;
  width: 58px;
  height: 58px;
  // border-left: 10px solid #fff;
  // border-top: 8px solid transparent;
  // border-bottom: 8px solid transparent;
  background: rgba(30, 30, 30, 0.1) url("../../assets/img/icons/phone.svg")
    no-repeat center center;
  border-radius: 100%;
  border: 2px solid transparent;
  opacity: 1;
  -webkit-animation: quick-alo-circle-img-anim-b6c09ab2 1s infinite ease-in-out;
  animation: quick-alo-circle-img-anim-b6c09ab2 1s infinite ease-in-out;
  transform-origin: 50% 50%;
  position: relative;
  a {
    width: 60px;
    height: 60px;
    position: absolute;
  }
}

.quick-alo-phone {
  display: block;
}

.quick-alo-phone.quick-alo-static {
  opacity: 0.6;
}
.quick-alo-phone.quick-alo-hover,
.quick-alo-phone:hover {
  opacity: 1;
}
.quick-alo-ph-circle {
  width: 160px;
  height: 160px;
  @media screen and (max-width: 765px) {
    display: none;
  }
  top: 20px;
  left: 20px;
  position: absolute;
  background-color: transparent;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid rgba(30, 30, 30, 0.4);
  border: 2px solid #bfebfc 9;
  opacity: 0.1;
  -webkit-animation: quick-alo-circle-anim 1.2s infinite ease-in-out;
  -moz-animation: quick-alo-circle-anim 1.2s infinite ease-in-out;
  -ms-animation: quick-alo-circle-anim 1.2s infinite ease-in-out;
  -o-animation: quick-alo-circle-anim 1.2s infinite ease-in-out;
  animation: quick-alo-circle-anim 1.2s infinite ease-in-out;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}
.quick-alo-phone.quick-alo-active .quick-alo-ph-circle {
  -webkit-animation: quick-alo-circle-anim 1.1s infinite ease-in-out !important;
  -moz-animation: quick-alo-circle-anim 1.1s infinite ease-in-out !important;
  -ms-animation: quick-alo-circle-anim 1.1s infinite ease-in-out !important;
  -o-animation: quick-alo-circle-anim 1.1s infinite ease-in-out !important;
  animation: quick-alo-circle-anim 1.1s infinite ease-in-out !important;
}
.quick-alo-phone.quick-alo-static .quick-alo-ph-circle {
  -webkit-animation: quick-alo-circle-anim 2.2s infinite ease-in-out !important;
  -moz-animation: quick-alo-circle-anim 2.2s infinite ease-in-out !important;
  -ms-animation: quick-alo-circle-anim 2.2s infinite ease-in-out !important;
  -o-animation: quick-alo-circle-anim 2.2s infinite ease-in-out !important;
  animation: quick-alo-circle-anim 2.2s infinite ease-in-out !important;
}
.quick-alo-phone.quick-alo-hover .quick-alo-ph-circle,
.quick-alo-phone:hover .quick-alo-ph-circle {
  border-color: #f37022;
  opacity: 0.5;
}
.quick-alo-phone.quick-alo-green.quick-alo-hover .quick-alo-ph-circle,
.quick-alo-phone.quick-alo-green:hover .quick-alo-ph-circle {
  border-color: #112047;
  border-color: #baf5a7 9;
  opacity: 0.5;
}
.quick-alo-phone.quick-alo-green .quick-alo-ph-circle {
  border-color: #f37022;
  border-color: #bfebfc 9;
  opacity: 0.5;
}
.quick-alo-phone.quick-alo-gray.quick-alo-hover .quick-alo-ph-circle,
.quick-alo-phone.quick-alo-gray:hover .quick-alo-ph-circle {
  border-color: #ccc;
  opacity: 0.5;
}
.quick-alo-phone.quick-alo-gray .quick-alo-ph-circle {
  border-color: #112047;
  opacity: 0.5;
}
.quick-alo-ph-circle-fill {
  width: 100px;
  height: 100px;
  @media screen and (max-width: 765px) {
    width: 66px;
    height: 66px;
  }
  top: 50px;
  left: 50px;
  position: absolute;
  background-color: #000;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid transparent;
  opacity: 0.1;
  -webkit-animation: quick-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -moz-animation: quick-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -ms-animation: quick-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -o-animation: quick-alo-circle-fill-anim 2.3s infinite ease-in-out;
  animation: quick-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}
.quick-alo-phone.quick-alo-active .quick-alo-ph-circle-fill {
  -webkit-animation: quick-alo-circle-fill-anim 1.7s infinite ease-in-out !important;
  -moz-animation: quick-alo-circle-fill-anim 1.7s infinite ease-in-out !important;
  -ms-animation: quick-alo-circle-fill-anim 1.7s infinite ease-in-out !important;
  -o-animation: quick-alo-circle-fill-anim 1.7s infinite ease-in-out !important;
  animation: quick-alo-circle-fill-anim 1.7s infinite ease-in-out !important;
}
.quick-alo-phone.quick-alo-static .quick-alo-ph-circle-fill {
  -webkit-animation: quick-alo-circle-fill-anim 2.3s infinite ease-in-out !important;
  -moz-animation: quick-alo-circle-fill-anim 2.3s infinite ease-in-out !important;
  -ms-animation: quick-alo-circle-fill-anim 2.3s infinite ease-in-out !important;
  -o-animation: quick-alo-circle-fill-anim 2.3s infinite ease-in-out !important;
  animation: quick-alo-circle-fill-anim 2.3s infinite ease-in-out !important;
  opacity: 0 !important;
}
.quick-alo-phone.quick-alo-hover .quick-alo-ph-circle-fill,
.quick-alo-phone:hover .quick-alo-ph-circle-fill {
  background-color: rgba(0, 175, 242, 0.5);
  background-color: #f37022 9;
  opacity: 0.75 !important;
}
.quick-alo-phone.quick-alo-green.quick-alo-hover .quick-alo-ph-circle-fill,
.quick-alo-phone.quick-alo-green:hover .quick-alo-ph-circle-fill {
  background-color: #112047;
  background-color: #baf5a7 9;
  opacity: 0.75 !important;
}
.quick-alo-phone.quick-alo-green .quick-alo-ph-circle-fill {
  background-color: rgb(173 64 0);
  background-color: #a6e3fa 9;
  opacity: 0.75 !important;
}
.quick-alo-phone.quick-alo-gray.quick-alo-hover .quick-alo-ph-circle-fill,
.quick-alo-phone.quick-alo-gray:hover .quick-alo-ph-circle-fill {
  background-color: rgba(204, 204, 204, 0.5);
  background-color: #ccc 9;
  opacity: 0.75 !important;
}
.quick-alo-phone.quick-alo-gray .quick-alo-ph-circle-fill {
  background-color: #112047;
  opacity: 0.75 !important;
}
.quick-alo-ph-img-circle {
  width: 60px;
  height: 60px;

  top: 70px;
  left: 70px;
  @media screen and (max-width: 765px) {
    width: 38px;
    height: 38px;
    top: 65px;
    left: 64px;
    background-size: 23px 23px;
  }
  position: absolute;
  background: rgba(30, 30, 30, 0.1) url("https://i.imgur.com/YWJeVO2.png")
    no-repeat center center;

  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid transparent;
  opacity: 0.7;
  -webkit-animation: quick-alo-circle-img-anim 1s infinite ease-in-out;
  -moz-animation: quick-alo-circle-img-anim 1s infinite ease-in-out;
  -ms-animation: quick-alo-circle-img-anim 1s infinite ease-in-out;
  -o-animation: quick-alo-circle-img-anim 1s infinite ease-in-out;
  animation: quick-alo-circle-img-anim 1s infinite ease-in-out;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}
.quick-alo-phone.quick-alo-active .quick-alo-ph-img-circle {
  -webkit-animation: quick-alo-circle-img-anim 1s infinite ease-in-out !important;
  -moz-animation: quick-alo-circle-img-anim 1s infinite ease-in-out !important;
  -ms-animation: quick-alo-circle-img-anim 1s infinite ease-in-out !important;
  -o-animation: quick-alo-circle-img-anim 1s infinite ease-in-out !important;
  animation: quick-alo-circle-img-anim 1s infinite ease-in-out !important;
}
.quick-alo-phone.quick-alo-static .quick-alo-ph-img-circle {
  -webkit-animation: quick-alo-circle-img-anim 0s infinite ease-in-out !important;
  -moz-animation: quick-alo-circle-img-anim 0s infinite ease-in-out !important;
  -ms-animation: quick-alo-circle-img-anim 0s infinite ease-in-out !important;
  -o-animation: quick-alo-circle-img-anim 0s infinite ease-in-out !important;
  animation: quick-alo-circle-img-anim 0s infinite ease-in-out !important;
}
.quick-alo-phone.quick-alo-hover .quick-alo-ph-img-circle,
.quick-alo-phone:hover .quick-alo-ph-img-circle {
  background-color: #f37022;
}
.quick-alo-phone.quick-alo-green.quick-alo-hover .quick-alo-ph-img-circle,
.quick-alo-phone.quick-alo-green:hover .quick-alo-ph-img-circle {
  background-color: #112047;
}
.quick-alo-phone.quick-alo-green .quick-alo-ph-img-circle {
  background-color: #f37022;
}
.quick-alo-phone.quick-alo-gray.quick-alo-hover .quick-alo-ph-img-circle,
.quick-alo-phone.quick-alo-gray:hover .quick-alo-ph-img-circle {
  background-color: #ccc;
}
.quick-alo-phone.quick-alo-gray .quick-alo-ph-img-circle {
  background-color: #112047;
}
@-moz-keyframes quick-alo-circle-anim {
  0% {
    -moz-transform: rotate(0) scale(0.5) skew(1deg);
    opacity: 0.1;
    -moz-opacity: 0.1;
    -webkit-opacity: 0.1;
    -o-opacity: 0.1;
  }
  30% {
    -moz-transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.5;
    -moz-opacity: 0.5;
    -webkit-opacity: 0.5;
    -o-opacity: 0.5;
  }
  100% {
    -moz-transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.6;
    -moz-opacity: 0.6;
    -webkit-opacity: 0.6;
    -o-opacity: 0.1;
  }
}
@-webkit-keyframes quick-alo-circle-anim {
  0% {
    -webkit-transform: rotate(0) scale(0.5) skew(1deg);
    -webkit-opacity: 0.1;
  }
  30% {
    -webkit-transform: rotate(0) scale(0.7) skew(1deg);
    -webkit-opacity: 0.5;
  }
  100% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    -webkit-opacity: 0.1;
  }
}
@-o-keyframes quick-alo-circle-anim {
  0% {
    -o-transform: rotate(0) kscale(0.5) skew(1deg);
    -o-opacity: 0.1;
  }
  30% {
    -o-transform: rotate(0) scale(0.7) skew(1deg);
    -o-opacity: 0.5;
  }
  100% {
    -o-transform: rotate(0) scale(1) skew(1deg);
    -o-opacity: 0.1;
  }
}
@-moz-keyframes quick-alo-circle-fill-anim {
  0% {
    -moz-transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.6;
  }
  50% {
    -moz-transform: rotate(0) -moz-scale(1) skew(1deg);
    opacity: 0.6;
  }
  100% {
    -moz-transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.6;
  }
}
@-webkit-keyframes quick-alo-circle-fill-anim {
  0% {
    -webkit-transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.6;
  }
  50% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.6;
  }
  100% {
    -webkit-transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.6;
  }
}
@-o-keyframes quick-alo-circle-fill-anim {
  0% {
    -o-transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.6;
  }
  50% {
    -o-transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.6;
  }
  100% {
    -o-transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.6;
  }
}
@-moz-keyframes quick-alo-circle-img-anim {
  0% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
    -moz-transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    -moz-transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    -moz-transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    -moz-transform: rotate(25deg) scale(1) skew(1deg);
  }
  50% {
    -moz-transform: rotate(0) scale(1) skew(1deg);
  }
  100% {
    -moz-transform: rotate(0) scale(1) skew(1deg);
  }
}
@-webkit-keyframes quick-alo-circle-img-anim {
  0% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg);
  }
  50% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
  }
  100% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
  }
}
@-o-keyframes quick-alo-circle-img-anim {
  0% {
    -o-transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
    -o-transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    -o-transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    -o-transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    -o-transform: rotate(25deg) scale(1) skew(1deg);
  }
  50% {
    -o-transform: rotate(0) scale(1) skew(1deg);
  }
  100% {
    -o-transform: rotate(0) scale(1) skew(1deg);
  }
}
.quick-alo-phone {
  position: fixed;
  background-color: transparent;
  width: 113px;
  height: 113px;
  cursor: pointer;
  z-index: 200000 !important;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transition: visibility 0.5s;
  left: -18px;
  bottom: 90px;
  @media screen and (max-width: 765px) {
    left: -39px;
    bottom: 69px;
  }
}
</style>
