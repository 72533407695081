<template>
  <div class="logo-amber">
    <router-link to="/" class="kcn" v-if="locale == 'vi'">
      <img
        class="logo_white"
        src="../../assets/img/logo/logo.svg"
        alt="Khu công nghiệp Tây Bắc Hồ Xá"
      />
      <img
        class="logo_blue"
        src="../../assets/img/logo/logo_blue.svg"
        alt="Khu công nghiệp Tây Bắc Hồ Xá"
      />
    </router-link>
    <router-link to="/" class="kcn" v-else>
      <img
        class="logo_white"
        src="../../assets/img/logo/logo_en.svg"
        alt="Khu công nghiệp Tây Bắc Hồ Xá"
      />
      <img
        class="logo_blue"
        src="../../assets/img/logo/logo_blue_en.svg"
        alt="Khu công nghiệp Tây Bắc Hồ Xá"
      />
    </router-link>
    <div class="logo-group">
      <p>Được vận hành và quản lý bởi</p>
      <img
        class="logo_white"
        src="../../assets/img/logo/amber-ft.svg"
        alt="logo amber"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["locale"]),
  },
}
</script>
